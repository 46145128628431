import { createTheming } from "@callstack/react-theme-provider";
import * as React from "react";
import { useWindowDimensions } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";

import themes from "../../constants/theme";
import { readData, storeData } from "../../services/asyncStorage/index";

// create context for theme
export const { ThemeProvider, withTheme, useTheme } = createTheming(
  themes.light
);

const Providers = (props: any) => {
  const { width, height } = useWindowDimensions();
  const [theme, setTheme] = React.useState(themes.light);

  // setting theme dimensions
  theme.dimensions.width = width;
  theme.dimensions.height = height;
  if (width <= 400) {
    theme.dimensions.screenType = "small";
    theme.dimensions.scale = 1;
  } else if (width <= 900) {
    theme.dimensions.screenType = "medium";
    theme.dimensions.scale = 1.1;
  } else {
    theme.dimensions.screenType = "large";
    theme.dimensions.scale = 1.2;
  }
  // setting theme funcs
  const themeSelector = (themeName: string) => {
    switch (themeName) {
      case "light":
        setTheme(themes.light);
        break;
      case "dark":
        setTheme(themes.dark);
        break;
      default:
        setTheme(themes.light);
    }
  };
  theme.funcs.getTheme = async () => {
    const userSelectedTheme = await readData("theme");
    themeSelector(userSelectedTheme ? userSelectedTheme : "light");
  };
  theme.funcs.updateTheme = async (themeName: string) => {
    try {
      themeSelector(themeName);
      await storeData("theme", themeName);
    } catch (err) {
      console.log("Unable to set theme");
    }
  };

  React.useEffect(() => {
    theme.funcs
      .getTheme()
      .catch((err) => console.log("Failed to update theme:", err));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SafeAreaProvider>{props.children}</SafeAreaProvider>
    </ThemeProvider>
  );
};

export default Providers;
