import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

export interface Anime {
  uid: string;
  name: string;
  description: string;
  image: string;
  liked: string[];
  shareCount: number;
}

export const getAnimeData = (uid: string): Promise<Anime> => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    getDoc(doc(db, "anime", uid))
      .then((docSnap) => {
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          throw new Error("animeNotRecordedYet");
        }
      })
      .then((animeData) => resolve(animeData))
      .catch((err) => reject(err));
  });
};

export const setAnimeData = (anime: Anime): Promise<Anime> => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    setDoc(doc(db, "anime", anime.uid), anime)
      .then(() => resolve(anime))
      .catch((err) => reject(err));
  });
};

// Custom helper functions for user actions
export const toggleLikeAnime = (
  uid: string,
  userId: string,
  isLiked: boolean
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    updateDoc(doc(db, "anime", uid), {
      liked: isLiked ? arrayRemove(userId) : arrayUnion(userId),
    })
      .then(() => {
        // update the user details too
        updateDoc(doc(db, "users", userId), {
          liked: isLiked ? arrayRemove(uid) : arrayUnion(uid),
        });
        resolve(true);
      })
      .catch((err) => reject(err));
  });
};
