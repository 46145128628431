import { getAuth } from "firebase/auth";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export interface UserPreferences {
  theme: string;
}

export interface User {
  uid: string;
  name: string;
  photo: string | null;
  email: string | null;
  following: string[];
  followers: string[];
  bookmarks: string[];
  liked: string[];
  preferences: UserPreferences;
}

export const getUserData = (uid: string): Promise<User> => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    getDoc(doc(db, "users", uid))
      .then((docSnap) => {
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          const auth = getAuth();
          if (auth.currentUser) {
            const newUser: User = {
              uid: auth.currentUser?.uid,
              name: auth.currentUser?.displayName
                ? auth.currentUser?.displayName
                : "Anonymous",
              photo: auth.currentUser?.photoURL,
              email: auth.currentUser?.email,
              following: [],
              followers: [],
              bookmarks: [],
              liked: [],
              preferences: {
                theme: "light",
              },
            };
            return setUserData(newUser);
          } else {
            throw new Error("User not logged in!");
          }
        }
      })
      .then((userData) => resolve(userData))
      .catch((err) => reject(err));
  });
};

export const setUserData = (user: User) => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    setDoc(doc(db, "users", user.uid), user)
      .then(() => resolve(user))
      .catch((err) => reject(err));
  });
};

export const bookmarkAnimeForUser = (
  uid: string,
  animeId: string,
  isRemove?: boolean
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    updateDoc(doc(db, "users", uid), {
      bookmarks: isRemove ? arrayRemove(animeId) : arrayUnion(animeId),
    })
      .then(() => resolve(true))
      .catch((err) => reject(err));
  });
};

export const followUserForUser = (
  uid: string,
  ownUid: string,
  isFollowed?: boolean
) => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    Promise.all([
      updateDoc(doc(db, "users", uid), {
        followers: isFollowed ? arrayRemove(ownUid) : arrayUnion(ownUid),
      }),
      updateDoc(doc(db, "users", ownUid), {
        following: isFollowed ? arrayRemove(uid) : arrayUnion(uid),
      }),
    ])
      .then(() => resolve(true))
      .catch((err) => reject(err));
  });
};

export const updateUser = (uid: string, update: any) => {
  return new Promise((resolve, reject) => {
    const db = getFirestore();
    updateDoc(doc(db, "users", uid), update)
      .then(() => resolve(true))
      .catch((err) => reject(err));
  });
};

export const uploadUserProfileImage = (
  uid: string,
  image: File | Blob
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const storageRef = getStorage();
    const userProfileImagesRef = ref(storageRef, `users/profileImages/${uid}`);
    uploadBytes(userProfileImagesRef, image)
      .then(() => getDownloadURL(userProfileImagesRef))
      .then((url) => resolve(url))
      .catch((err) => reject(err));
  });
};
