import React from "react";
import { Text } from "react-native";

import { useTheme } from "../Providers";
import { HNParams } from "./type";

const H1 = ({ text, style = {} }: HNParams) => {
  const theme = useTheme();
  return (
    <Text
      style={[
        {
          fontSize: theme.fonts.large * theme.dimensions.scale,
          fontWeight: "800",
          color: theme.colors.text,
          fontFamily: "JosefinSans",
        },
        style,
      ]}
    >
      {text}
    </Text>
  );
};

export default H1;
