import Back from "../Icons/Back";
import Menu from "../Icons/Menu";
import Search from "../Icons/Search";

export default {
  home: {
    name: "home",
    text: "Welcome to AnimeVeda",
    leftIcon: Menu,
    rightIcon: Search,
  },
  search: {
    name: "search",
    text: "Search",
    leftIcon: Back,
    rightIcon: null,
  },
  profile: {
    name: "profile",
    text: "Profile",
    leftIcon: Back,
    rightIcon: null,
  },
  bookmarks: {
    name: "bookmarks",
    text: "Bookmarks",
    leftIcon: Back,
    rightIcon: null,
  },
  settings: {
    name: "settings",
    text: "Settings",
    leftIcon: Back,
    rightIcon: null,
  },
};
