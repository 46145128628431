import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import React from "react";

import DrawerContent from "../components/Drawer";
import Header from "../components/Header";
import { withTheme } from "../components/Providers";
import { ScreenType } from "../constants/theme";
import useUserData from "../hooks/useUserData";
import Bookmarks from "../screens/Bookmarks";
import Profile from "../screens/Profile";
import Settings from "../screens/Settings";
import HomeStack from "./HomeStack";

const Drawer = createDrawerNavigator();

const drawerTypeSelector = (screenType: ScreenType) => {
  if (screenType === "medium" || screenType === "large") {
    return "permanent";
  }
  return "front";
};

export type MainDrawerProps = {
  theme: any;
};

const MainDrawer = (props: MainDrawerProps) => {
  const screenType = props.theme.dimensions.screenType;
  const [userData, _] = useUserData();

  React.useEffect(() => {
    if (userData?.preferences?.theme === undefined) {
      return;
    }
    if (userData?.preferences?.theme !== props.theme.name) {
      props.theme.funcs.updateTheme(userData?.preferences?.theme);
    }
  }, [userData]);

  return (
    <NavigationContainer>
      <Drawer.Navigator
        initialRouteName="home-stack"
        screenOptions={{
          header: (props) => {
            if (props.route.name === "home-stack") {
              return null;
            }
            return <Header {...props} />;
          },
          sceneContainerStyle: {
            width:
              screenType === "large"
                ? props.theme.dimensions.width - 320
                : props.theme.dimensions.width,
            backgroundColor: props.theme.colors.background,
          },
          drawerType: drawerTypeSelector(screenType),
          lazy: true,
        }}
        drawerContent={(props) => <DrawerContent {...props} />}
        backBehavior="initialRoute"
      >
        <Drawer.Screen name="home-stack" component={HomeStack} />
        <Drawer.Screen name="profile" component={Profile} />
        <Drawer.Screen name="bookmarks" component={Bookmarks} />
        <Drawer.Screen name="settings" component={Settings} />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};

export default withTheme(MainDrawer);
