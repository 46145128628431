import * as Sharing from "expo-sharing";
import html2canvas from "html2canvas";

export default async (ref: any) => {
  const isSharingAvailable = await Sharing.isAvailableAsync();
  html2canvas(ref.current, {
    backgroundColor: "#FFFFFF",
    logging: true,
    useCORS: true,
  }).then(async (canvas) => {
    canvas.style.display = "none";
    const image = canvas.toDataURL("png");
    /**
     * Web Share API
     * expo-share relies on Web Share API on browsers which has very limited
     * support when it comes to desktop browsers.
     * If on desktop, just download the image and let the user have fun :>
     */
    if (!isSharingAvailable) {
      const a = document.createElement("a");
      a.setAttribute("href", image);
      a.setAttribute("download", "animeVeda");
      a.click();
    } else {
      Sharing.shareAsync(image);
    }
  });
};
