import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

import { useTheme } from "../Providers";

const Logout = (props: SvgProps) => {
  const theme = useTheme();
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M7 19H3a2 2 0 01-2-2V3a2 2 0 012-2h4m7 14l5-5m0 0l-5-5m5 5H7"
        stroke={theme.colors.text}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Logout;
