import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

import { useTheme } from "../Providers";

const Share = (props: SvgProps) => {
  const theme = useTheme();
  return (
    <Svg width={24} height={24} fill="none" {...props}>
      <Path
        d="M18 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM18 22a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98"
        stroke={theme.colors.text}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Share;
