import * as React from "react";
import {
  TextInput as DefaultTextInput,
  TextInputProps,
  useWindowDimensions,
} from "react-native";

import { useTheme } from "../Providers";

export type TextInputParams = TextInputProps;

const TextInput = ({
  style,
  value,
  onChangeText,
  placeholder,
  secureTextEntry,
  ...props
}: TextInputParams) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  return (
    <DefaultTextInput
      keyboardType="ascii-capable"
      style={[
        {
          fontSize: theme.fonts.medium,
          fontWeight: "600",
          fontFamily: "JosefinSans",
          color: theme.colors.text,
          borderColor: theme.colors.text,
          borderRadius: theme.roundness,
          borderWidth: 1,
          paddingLeft: 10,
          margin: 4,
          height: 40,
          width: width < 400 ? width * 0.8 : 360,
        },
        style,
      ]}
      placeholderTextColor={theme.colors.text}
      value={value}
      onChangeText={onChangeText}
      placeholder={placeholder}
      secureTextEntry={secureTextEntry}
      {...props}
    />
  );
};

export default TextInput;
