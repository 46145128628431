import { getFirestore, doc, setDoc } from "firebase/firestore";

export interface ErrorReport {
  error: string;
  stack?: string;
  name: string;
  info: string;
}

export const setErrorData = (errorReport: ErrorReport) => {
  const db = getFirestore();
  setDoc(doc(db, "error", Date.now().toString()), errorReport).catch((err) => {
    console.log(err);
  });
};
