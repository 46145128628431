import * as React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";

import FlatListItemBase from "../FlatListItem/FlatListItemBase";
import H4 from "../Typography/H4";

export interface FlatStatGridItem {
  text: string;
  icon: JSX.Element;
  onPress: () => void;
}

export interface FlatStatsGridProps {
  primary: FlatStatGridItem;
  secondary: FlatStatGridItem;
  tertiary: FlatStatGridItem;
}

const FlatStatsGrid: React.FC<FlatStatsGridProps> = ({
  primary,
  secondary,
  tertiary,
}) => {
  return (
    <FlatListItemBase
      style={{
        marginTop: 16,
        paddingHorizontal: 20,
        justifyContent: "center",
      }}
    >
      <View style={styles.actionButtonWrapper}>
        <TouchableOpacity onPress={primary.onPress}>
          {primary.icon}
        </TouchableOpacity>
        <H4 text={primary.text} style={{ marginTop: 4 }} />
      </View>
      <View style={styles.actionButtonWrapper}>
        <TouchableOpacity onPress={secondary.onPress}>
          {secondary.icon}
        </TouchableOpacity>
        <H4 text={secondary.text} style={{ marginTop: 4 }} />
      </View>
      <View style={styles.actionButtonWrapper}>
        <TouchableOpacity onPress={tertiary.onPress}>
          {tertiary.icon}
        </TouchableOpacity>
        <H4 text={tertiary.text} style={{ marginTop: 4 }} />
      </View>
    </FlatListItemBase>
  );
};

const styles = StyleSheet.create({
  actionButtonWrapper: {
    width: 100,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default FlatStatsGrid;
