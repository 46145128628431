import * as WebBrowser from "expo-web-browser";
import { getAuth } from "firebase/auth";
import * as React from "react";
import {
  ScrollView,
  Image,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import WebView from "react-native-webview";

import Chip from "../../components/Chip";
import FlatStatsGrid from "../../components/FlatStatsGrid";
import Back from "../../components/Icons/Back";
import Bookmark from "../../components/Icons/Bookmark";
import Heart from "../../components/Icons/Heart";
import Share from "../../components/Icons/Share";
import Loader from "../../components/Loader";
import { useTheme } from "../../components/Providers";
import Sharable from "../../components/Sharable/Sharable";
import H1 from "../../components/Typography/H1";
import H2 from "../../components/Typography/H2";
import H3 from "../../components/Typography/H3";
import useAnimeFuncs from "../../hooks/useAnimeFuncs";
import Jikan, { AnimeDetailRespType } from "../../services/jikan";

const trailerHeightRatio = (dimensions: any) => {
  const { width, screenType } = dimensions;
  if (screenType === "large" || screenType === "medium") {
    return (width / 2) * 0.5;
  }
  return width / 2;
};

const Anime = (props: any) => {
  // global things for screen
  const { name, image, key } = props.route.params;
  const auth = getAuth();
  const userId = String(auth.currentUser?.uid);
  const theme = useTheme();

  // component states
  const [animeDetails, setAnimeDetails] =
    React.useState<AnimeDetailRespType | null>(null);
  const [isShareOpen, setIsShareOpen] = React.useState(false);
  // custom anime hooks
  const [ownAnimeDetails, userData, animeFuncCaller] = useAnimeFuncs(
    String(key),
    userId
  );

  React.useEffect(() => {
    Jikan.anime
      .details(key)
      .then((data) => setAnimeDetails(data))
      .catch((err) => console.log(err));
  }, []);

  const stylesDynamic = StyleSheet.create({
    headerImage: {
      width: "100%",
      height: theme.dimensions.height / 2,
      borderBottomLeftRadius: theme.roundness,
      borderBottomRightRadius: theme.roundness,
    },
    trailer: {
      width: "100%",
      height: trailerHeightRatio(theme.dimensions),
      borderRadius: theme.roundness,
      opacity: 0.99,
    },
    malButton: {
      borderRadius: theme.roundness,
      borderColor: theme.colors.text,
      borderWidth: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 16,
      marginTop: 22,
    },
    itemContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
      height: 80,
      width: theme.dimensions.screenType === "large" ? 300 : 110,
    },
  });
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <View style={styles.animeHeader}>
        <View style={styles.animeHeaderInner}>
          <TouchableOpacity onPress={() => props.navigation.goBack()}>
            <Back width={24} height={24} />
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Image style={stylesDynamic.headerImage} source={{ uri: image }} />
        <View style={styles.animeInfoView}>
          <H1 text={name} />
          <View
            style={{
              alignSelf:
                theme.dimensions.screenType === "large"
                  ? "flex-end"
                  : "stretch",
            }}
          >
            <FlatStatsGrid
              primary={{
                text: `${
                  ownAnimeDetails ? ownAnimeDetails.liked.length : 0
                } likes`,
                icon: (
                  <Heart
                    isSelected={Boolean(
                      ownAnimeDetails?.liked.includes(userId)
                    )}
                  />
                ),
                onPress: () => animeFuncCaller({ type: "like" }),
              }}
              secondary={{
                text: "Share",
                icon: <Share />,
                onPress: () => setIsShareOpen(true),
              }}
              tertiary={{
                text: "Bookmark",
                icon: (
                  <Bookmark
                    isSelected={Boolean(
                      userData?.bookmarks.includes(String(key))
                    )}
                  />
                ),
                onPress: () => animeFuncCaller({ type: "bookmark" }),
              }}
            />
          </View>
          {animeDetails ? (
            <>
              <View style={{ ...styles.subContainer, marginTop: 0 }}>
                <H2 text="Description" style={{ marginBottom: 12 }} />
                <H3 text={animeDetails.synopsis} />
              </View>
              <View style={styles.subContainer}>
                <H2 text="Genres" />
                <View style={styles.genres}>
                  {animeDetails.genres.map((genre: any) => {
                    return (
                      <Chip
                        key={genre.mal_id}
                        name={genre.name}
                        onPress={() => {}}
                      />
                    );
                  })}
                </View>
              </View>
              <View style={styles.subContainer}>
                <H2 text="Status" />
                <View style={styles.genres}>
                  <Chip name={animeDetails.status} onPress={() => {}} />
                </View>
              </View>
              <View style={styles.subContainer}>
                <H2 text="Trailer" style={{ marginBottom: 8 }} />
                {Platform.OS === "web" ? (
                  <View style={stylesDynamic.trailer}>
                    <iframe
                      allowFullScreen
                      height="100%"
                      width="100%"
                      title={name}
                      src={animeDetails.trailer_url}
                    />
                  </View>
                ) : (
                  <WebView
                    containerStyle={stylesDynamic.trailer}
                    androidHardwareAccelerationDisabled={false}
                    allowsFullscreenVideo
                    source={{
                      uri: animeDetails.trailer_url,
                    }}
                  />
                )}
              </View>
              <View style={[styles.subContainer, { marginTop: 38 }]}>
                <H2 text="MyAnimeList Stats" />
                <View style={[styles.malStatsGrid]}>
                  <View style={styles.malStatsGridRow}>
                    <View style={[stylesDynamic.itemContainer]}>
                      <H2 text={String(animeDetails.mal_stats.favorites)} />
                      <H3 text="Favorites" />
                    </View>
                    <View style={[stylesDynamic.itemContainer]}>
                      <H2 text={String(animeDetails.mal_stats.members)} />
                      <H3 text="Members" />
                    </View>
                    <View style={[stylesDynamic.itemContainer]}>
                      <H2 text={String(animeDetails.mal_stats.popularity)} />
                      <H3 text="Popularity" />
                    </View>
                  </View>
                  <View style={styles.malStatsGridRow}>
                    <View style={[stylesDynamic.itemContainer]}>
                      <H2 text={String(animeDetails.mal_stats.rank)} />
                      <H3 text="Rank" />
                    </View>
                    <View style={[stylesDynamic.itemContainer]}>
                      <H2 text={String(animeDetails.mal_stats.score)} />
                      <H3 text="Score" />
                    </View>
                    <View style={[stylesDynamic.itemContainer]}>
                      <H2 text={String(animeDetails.mal_stats.scored_by)} />
                      <H3 text="Scored By" />
                    </View>
                  </View>
                </View>
                <TouchableOpacity
                  style={stylesDynamic.malButton}
                  onPress={() => WebBrowser.openBrowserAsync(animeDetails.url)}
                >
                  <H3 text="View on MyAnimeList" style={{ color: theme.colors.link }} />
                </TouchableOpacity>
              </View>
            </>
          ) : (
            <Loader />
          )}
        </View>
      </ScrollView>
      <Sharable
        name={name}
        description={animeDetails?.synopsis || ""}
        image={image}
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  animeHeader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    padding: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 99,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
  },
  animeHeaderInner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  animeInfoView: {
    padding: 12,
    marginBottom: 24,
    marginTop: 24,
  },
  actionButtonWrapper: {
    width: 100,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subContainer: {
    marginTop: 24,
  },
  genres: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  malStatsGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  malStatsGridRow: {
    flexDirection: "row",
  },
});

export default Anime;
