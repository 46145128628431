import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { withTheme } from "../Providers";
import H3 from "../Typography/H3";

export type ChipProps = {
  name: string;
  onPress: () => void;
  theme: any;
  style?: any;
};

const Chip = (props: ChipProps) => {
  const styles = StyleSheet.create({
    mainChipWrapper: {
      backgroundColor: props.theme.colors.surface,
      padding: 7,
      margin: 7,
      borderRadius: props.theme.roundness,
      elevation: props.theme.elevation,
      display: "flex",
      alignSelf: "flex-start",
    },
  });
  return (
    <TouchableOpacity
      style={[styles.mainChipWrapper, props.style]}
      onPress={props.onPress}
    >
      <H3 text={props.name} />
    </TouchableOpacity>
  );
};

export default withTheme(Chip);
