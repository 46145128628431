import * as ImagePicker from "expo-image-picker";
import { getAuth, signOut } from "firebase/auth";
import * as React from "react";
import { View, ScrollView, Image } from "react-native";

import Button from "../../components/Button";
import FlatListItem from "../../components/FlatListItem";
import Logout from "../../components/Icons/Logout";
import Modal from "../../components/Modal";
import { useTheme } from "../../components/Providers";
import TextInput from "../../components/TextInput";
import H2 from "../../components/Typography/H2";
import H3 from "../../components/Typography/H3";
import themes from "../../constants/theme";
import useUserData from "../../hooks/useUserData";
import { uploadUserProfileImage } from "../../services/firebase/user";

const Settings = (props: any) => {
  const theme = useTheme();
  // component states
  const [userData, userFuncCaller] = useUserData();
  // modal states
  const [isOpen, setIsOpen] = React.useState<
    "name" | "profilePicture" | "theme" | null
  >(null);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string | null>(null);
  const [image, setImage] = React.useState<string | null>(null);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
    });

    if (!result.cancelled) {
      setImage(result.uri);
      setIsOpen("profilePicture");
    }
  };

  const uploadImage = async () => {
    if (processing) {
      return;
    }
    setProcessing(true);
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", String(image), true);
      xhr.send(null);
    });
    uploadUserProfileImage(String(userData?.uid), blob)
      .then((url) => {
        userFuncCaller({ type: "update", payload: { photo: url } });
        setProcessing(false);
        setIsOpen(null);
      })
      .catch((err) => console.log(err));
  };

  const onPressLogout = () => {
    const auth = getAuth();
    signOut(auth);
  };

  // the selected theme
  const userTheme = userData?.preferences?.theme
    ? userData?.preferences.theme
    : "light";

  const renderModalContent = () => {
    switch (isOpen) {
      case "name":
        return (
          <View
            style={{
              width: "100%",
            }}
          >
            <H2 text="Update Your Name" />
            <TextInput
              value={name !== null ? name : String(userData?.name)}
              onChangeText={(t) => setName(t)}
              placeholder="Your sweet name"
              style={{
                width: "100%",
                margin: 0,
                marginTop: 10,
              }}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button
                text="Update"
                onPress={() => {
                  userFuncCaller({ type: "update", payload: { name } });
                  setIsOpen(null);
                }}
              />
              <Button text="Cancel" onPress={() => setIsOpen(null)} />
            </View>
          </View>
        );
      case "profilePicture":
        return (
          <View
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <H2
              style={{ alignSelf: "flex-start" }}
              text="Upload Profile Picture"
            />
            <Image
              style={{
                borderRadius: theme.roundness,
                width: "100%",
                height: 280,
                marginTop: 10,
              }}
              source={{ uri: String(image) }}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 10,
                width: "100%",
              }}
            >
              <Button
                text="Upload"
                loading={processing}
                onPress={uploadImage}
              />
              <Button
                text="Cancel"
                onPress={() => {
                  if (processing) {
                    return;
                  }
                  setIsOpen(null);
                }}
              />
            </View>
          </View>
        );
      case "theme":
        return (
          <View
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <H2 style={{ alignSelf: "flex-start" }} text="Change App Theme" />
            <View
              style={{
                width: "100%",
                marginTop: 12,
              }}
            >
              {Object.keys(themes).map((themeName) => {
                return (
                  <FlatListItem
                    style={{
                      height: 40,
                      marginBottom: 0,
                    }}
                    title={`${themeName.toUpperCase()} ${
                      userTheme === themeName ? "(selected)" : ""
                    }`}
                    onPress={() => {
                      theme.funcs.updateTheme(themeName);
                      userFuncCaller({
                        type: "update",
                        payload: {
                          preferences: {
                            theme: themeName,
                          },
                        },
                      });
                      userFuncCaller({
                        type: "get",
                        payload: {},
                      });
                    }}
                  />
                );
              })}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 10,
                width: "100%",
              }}
            >
              <Button
                text="Close"
                onPress={() => {
                  if (processing) {
                    return;
                  }
                  setIsOpen(null);
                }}
              />
            </View>
          </View>
        );
      default:
        return null;
    }
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingVertical: 20,
          paddingHorizontal: 23,
          width:
            theme.dimensions.screenType === "large"
              ? 400
              : theme.dimensions.width,
        }}
      >
        <H3 text="Edit Profile" style={{ marginBottom: 8 }} />
        <FlatListItem title="Update Name" onPress={() => setIsOpen("name")} />
        <FlatListItem title="Upload Profile Picture" onPress={pickImage} />
        <H3 text="General" style={{ marginBottom: 8 }} />
        <FlatListItem title="Theme" onPress={() => setIsOpen("theme")} />
        <FlatListItem
          title="Logout"
          rightIcon={Logout}
          onPress={onPressLogout}
        />
      </ScrollView>
      <Modal isOpen={Boolean(isOpen)} onClose={() => setIsOpen(null)}>
        {renderModalContent()}
      </Modal>
    </View>
  );
};

export default Settings;
