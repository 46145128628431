import { getAuth } from "firebase/auth";
import * as React from "react";
import { ScrollView, View } from "react-native";

import AnimeLister from "../../components/AnimeLister";
import H3 from "../../components/Typography/H3";
import { getAnimeData } from "../../services/firebase/anime";
import { getUserData } from "../../services/firebase/user";
import { AnimeTopRespType } from "../../services/jikan";

const Bookmarks = (props: any) => {
  const auth = getAuth();
  const userId = String(auth.currentUser?.uid);

  // component states
  const [loading, setLoading] = React.useState(true);
  const [bookmarkedAnime, setBookmarkedAnime] = React.useState<
    AnimeTopRespType[]
  >([]);

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      getUserData(userId)
        .then((user) => {
          const promiseAllArray = user.bookmarks.map((animeId: string) => {
            return getAnimeData(animeId);
          });
          return Promise.all(promiseAllArray);
        })
        .then((bookmarked) =>
          setBookmarkedAnime(
            bookmarked.map((anime) => ({
              mal_id: Number(anime.uid),
              title: anime.name,
              synopsis: anime.description,
              image_url: anime.image,
            }))
          )
        )
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    });
    return unsubscribe;
  }, []);

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <H3
        text="Saved By You"
        style={{ marginHorizontal: 22, marginBottom: 8 }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingVertical: 20 }}
      >
        <AnimeLister
          loading={loading}
          anime={bookmarkedAnime}
          navigate={props.navigation.navigate}
        />
      </ScrollView>
      {loading === false && bookmarkedAnime.length === 0 && (
        <H3
          text="You haven't bookmarked any anime yet"
          style={{ marginHorizontal: 20 }}
        />
      )}
    </View>
  );
};

export default Bookmarks;
