import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

import { useTheme } from "../Providers";

const Menu = (props: SvgProps) => {
  const theme = useTheme();
  return (
    <Svg width={20} height={14} viewBox="0 0 20 14" fill="none" {...props}>
      <Path
        d="M1 7h18M1 1h18M1 13h18"
        stroke={theme.colors.text}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(Menu);
