import { getAuth } from "firebase/auth";
import * as React from "react";
import { View, ScrollView, RefreshControl } from "react-native";

import AnimeLister from "../../components/AnimeLister";
import H4 from "../../components/Typography/H3";
import { getUserData } from "../../services/firebase/user";
import Jikan, { AnimeTopRespType } from "../../services/jikan";

const Home = (props: any) => {
  const auth = getAuth();
  const userId = String(auth.currentUser?.uid);

  // component states
  const [topAnime, setTopAnime] = React.useState<AnimeTopRespType[] | null>(
    null
  );
  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    Jikan.anime
      .top()
      .then((al) => setTopAnime(al))
      .finally(() => setRefreshing(false));
  }, []);

  React.useEffect(() => {
    const subscribe = props.navigation.addListener("focus", () => {
      getUserData(userId).catch((err) => console.log(err.message));
      Jikan.anime.top().then((al) => setTopAnime(al));
    });
    return subscribe;
  }, []);

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <H4
        text="Trending Today"
        style={{ marginHorizontal: 22, marginTop: 10 }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingVertical: 20 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <AnimeLister
          loading={topAnime === null}
          anime={topAnime ? topAnime : []}
          navigate={props.navigation.navigate}
        />
      </ScrollView>
    </View>
  );
};

export default Home;
