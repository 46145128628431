import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

import { useTheme } from "../Providers";

const Bookmark = (props: SvgProps & { isSelected?: boolean }) => {
  const theme = useTheme();
  return (
    <Svg
      width={16}
      height={24}
      viewBox="0 0 16 20"
      fill={props.isSelected ? theme.colors.text : "none"}
      {...props}
    >
      <Path
        d="M15 19l-7-5-7 5V3a2 2 0 012-2h10a2 2 0 012 2v16z"
        stroke={theme.colors.text}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Bookmark;
