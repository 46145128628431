import * as React from "react";
import { View } from "react-native";

import { withTheme } from "../components/Providers";
// importing stacks
import LoginStack from "./LoginStack";
import MainDrawer from "./MainDrawer";

export type RootNavigatorParams = {
  isLoggedIn: boolean;
  theme: any;
};

const RootNavigator = ({ isLoggedIn, theme }: RootNavigatorParams) => {
  if (isLoggedIn) {
    return (
      <View
        style={{
          width: theme.dimensions.width,
          height: theme.dimensions.height,
          backgroundColor: theme.colors.background,
        }}
      >
        <MainDrawer />
      </View>
    );
  }
  return <LoginStack />;
};

export default withTheme(RootNavigator);
