import * as React from "react";
import { View, StyleSheet, Image } from "react-native";

import Alert, { AlertProps } from "../Alert";
import Button from "../Button";
import Modal from "../Modal";
import { useTheme } from "../Providers";
import H2 from "../Typography/H2";
import H3 from "../Typography/H3";
import H4 from "../Typography/H4";
// import platform specific helper
import onPressShare from "./onPressShare";

export type SharableProps = {
  name: string;
  description: string;
  image: string;
  isOpen: boolean;
  onClose: () => void;
};

const Sharable: React.FC<SharableProps> = (props) => {
  const theme = useTheme();
  const cardRef = React.useRef(null);
  // alert modal states
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState<AlertProps>({
    title: "",
    message: "",
  });

  const onSharePress = async () => {
    try {
      await onPressShare(cardRef);
    } catch (err) {
      setAlertState({
        title: "Error",
        message: err.message,
        onConfirmText: "Close",
      });
      setIsAlertOpen(true);
    }
  };

  const styles = StyleSheet.create({
    sharableWrapper: {
      backgroundColor: theme.colors.surface,
      padding: 7,
    },
    mainCardWrapper: {
      backgroundColor: theme.colors.surface,
      padding: 7,
      borderRadius: theme.roundness,
      borderColor: theme.colors.text,
      borderWidth: 1,
      elevation: theme.elevation,
    },
    animeImage: {
      height: 140,
      backgroundColor: "grey",
      borderRadius: theme.roundness,
      marginBottom: 4,
    },
    textMarginTop: {
      marginTop: 2 * theme.dimensions.scale,
    },
  });
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <View
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <H2 style={{ alignSelf: "flex-start" }} text="Share" />
        <View>
          <View style={styles.sharableWrapper} ref={cardRef}>
            <View style={styles.mainCardWrapper}>
              <Image source={{ uri: props.image }} style={styles.animeImage} />
              <H3 style={styles.textMarginTop} text={props.name} />
              <H4
                style={styles.textMarginTop}
                text={
                  props.description.length > 128
                    ? `${props.description.slice(0, 128)}...more`
                    : props.description
                }
              />
            </View>
            <H4 style={{ alignSelf: "flex-end" }} text="AnimeVeda" />
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 10,
            width: "100%",
          }}
        >
          <Button text="Share" onPress={onSharePress} />
          <Button text="Close" onPress={props.onClose} />
        </View>
      </View>
      <Alert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        {...alertState}
      />
    </Modal>
  );
};

export default Sharable;
