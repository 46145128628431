import * as React from "react";
import { ActivityIndicator, ActivityIndicatorProps } from "react-native";

import { withTheme } from "../Providers";

const Loader: React.FC<ActivityIndicatorProps & { theme: any }> = ({
  size = "large",
  theme,
}) => {
  return <ActivityIndicator color={theme.colors.text} size={size} />;
};

export default withTheme(Loader);
