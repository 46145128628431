import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";

import Header from "../components/Header";
import { withTheme } from "../components/Providers";
import Anime from "../screens/Anime";
import Home from "../screens/Home";
import Search from "../screens/Search";

const Stack = createNativeStackNavigator();

const HomeStack = (props: any) => {
  return (
    <Stack.Navigator
      initialRouteName="home"
      screenOptions={{
        header: (props) => {
          if (props.route.name === "anime") {
            return null;
          }
          return <Header {...props} />;
        },
        contentStyle: {
          backgroundColor: props.theme.colors.background,
        },
        animation: "slide_from_right"
      }}
    >
      <Stack.Screen name="home" component={Home} />
      <Stack.Screen name="anime" component={Anime} />
      <Stack.Screen name="search" component={Search} />
    </Stack.Navigator>
  );
};

export default withTheme(HomeStack);
