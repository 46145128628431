import React from "react";
import { ActivityIndicator, Pressable, PressableProps } from "react-native";

import { withTheme } from "../Providers";
import H2 from "../Typography/H3";

export type ButtonParams = PressableProps & {
  text: string;
  loading?: boolean;
  style?: any;
  theme: any;
};

const Button = (props: ButtonParams) => {
  return (
    <Pressable
      {...props}
      style={({ pressed }) => [
        {
          backgroundColor: props.theme.colors.surface,
          borderWidth: 1,
          borderRadius: props.theme.roundness,
          borderColor: pressed
            ? props.theme.colors.text
            : props.theme.colors.surface,
          paddingVertical: 4,
          paddingHorizontal: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
        props.style,
      ]}
    >
      {props.loading ? (
        <ActivityIndicator size="small" color={props.theme.colors.text} />
      ) : (
        <H2 text={props.text.toUpperCase()} />
      )}
    </Pressable>
  );
};

export default withTheme(Button);
