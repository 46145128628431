import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { useTheme } from "../Providers";
import H2 from "../Typography/H2";
import config from "./config";

const Header = (props) => {
  const theme = useTheme();
  const routeConf = !config[props.route.name]
    ? config["home"]
    : config[props.route.name];
  const renderRightIcon = () => {
    if (routeConf.rightIcon) {
      return (
        <TouchableOpacity
          onPress={() => {
            if (routeConf.name === "home") {
              props.navigation.navigate("search");
            }
          }}
        >
          <routeConf.rightIcon width={24} height={24} />
        </TouchableOpacity>
      );
    }
    return null;
  };
  return (
    <View
      style={[
        styles.headerWrapper,
        {
          backgroundColor: theme.colors.background,
        },
      ]}
    >
      <View style={styles.innerContainer}>
        <TouchableOpacity
          onPress={() => {
            if (routeConf.name === "home") {
              props.navigation.toggleDrawer();
            } else {
              props.navigation.goBack();
            }
          }}
        >
          <routeConf.leftIcon width={24} height={24} />
        </TouchableOpacity>
        <H2 style={{ marginLeft: 10 }} text={routeConf.text} />
      </View>
      {renderRightIcon()}
    </View>
  );
};

const styles = StyleSheet.create({
  headerWrapper: {
    padding: 22,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default Header;
