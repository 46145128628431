import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";

import { withTheme } from "../components/Providers";
// importing screens
import Login from "../screens/Login";

const Stack = createNativeStackNavigator();

const LoginStack = (props: any) => {
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName="login"
        screenOptions={{
          contentStyle: {
            backgroundColor: props.theme.colors.background,
          },
          headerShown: false,
        }}
      >
        <Stack.Screen name="login" component={Login} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default withTheme(LoginStack);
