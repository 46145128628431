import fetch from "cross-fetch";

const url4: string = "https://api.jikan.moe/v4/";

export type AnimeTopRespType = {
  mal_id: number;
  title: string;
  synopsis: string;
  image_url: string;
};

export type AnimeMalStats = {
  score: number;
  scored_by: number;
  rank: number;
  popularity: number;
  members: number;
  favorites: number;
};

export type AnimeDetailRespType = {
  mal_id: number;
  url: string;
  image_url: string;
  trailer_url: string;
  title: string;
  status: string;
  synopsis: string;
  genres: any[];
  mal_stats: AnimeMalStats;
};

export const defaultAnimeDetails: AnimeDetailRespType = {
  mal_id: 0,
  url: "",
  image_url: "",
  trailer_url: "",
  title: "",
  status: "",
  synopsis: "",
  genres: [],
  mal_stats: {
    score: 0,
    scored_by: 0,
    rank: 0,
    popularity: 0,
    members: 0,
    favorites: 0,
  },
};

export type AnimeType = {
  top: () => Promise<AnimeTopRespType[]>;
  details: (animeId: string) => Promise<AnimeDetailRespType>;
  search: (animeName: string) => Promise<AnimeTopRespType[]>;
};

const anime: AnimeType = {
  top: () => {
    return new Promise((resolve, reject) => {
      fetch(url4 + "top/anime")
        .then((jsonData) => jsonData.json())
        .then((data) =>
          data.data.map((animeV4: any) => ({
            mal_id: animeV4.mal_id,
            title: animeV4.title_english || animeV4.title,
            synopsis: animeV4.synopsis,
            image_url: animeV4.images.jpg.large_image_url,
          }))
        )
        .then((top: AnimeTopRespType[]) => resolve(top))
        .catch((err) => reject(err));
    });
  },
  details: (animeId) => {
    return new Promise((resolve, reject) => {
      fetch(url4 + "anime/" + animeId)
        .then((jsonData) => jsonData.json())
        .then((dataRaw) => {
          const data = dataRaw.data;
          const animeDetails: AnimeDetailRespType = {
            mal_id: Number(animeId),
            url: data.url || "",
            image_url: data.images.jpg.large_image_url,
            trailer_url: data.trailer.embed_url,
            title: data.title_english || data.title,
            synopsis: data.synopsis,
            status: data.status,
            genres: data.genres,
            mal_stats: {
              score: data.score,
              scored_by: data.scored_by,
              rank: data.rank,
              popularity: data.popularity,
              members: data.members,
              favorites: data.favorites,
            },
          };
          resolve(animeDetails);
        })
        .catch((err) => reject(err));
    });
  },
  search: (animeName) => {
    return new Promise((resolve, reject) => {
      fetch(url4 + "anime?q=" + animeName + "&page=1")
        .then((jsonData) => jsonData.json())
        .then((data) =>
          data.data.map((animeV4: any) => ({
            mal_id: animeV4.mal_id,
            title: animeV4.title_english || animeV4.title,
            synopsis: animeV4.synopsis || "",
            image_url: animeV4.images.jpg.large_image_url,
          }))
        )
        .then((animeSearchResults: AnimeTopRespType[]) =>
          resolve(animeSearchResults)
        )
        .catch((err) => reject(err));
    });
  },
};

export type JikanType = {
  anime: AnimeType;
};

const Jikan: JikanType = {
  anime,
};

export default Jikan;
