import * as React from "react";
import { View, ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import AnimeLister from "../../components/AnimeLister";
import * as SearchIcon from "../../components/Icons/Search";
import { useTheme } from "../../components/Providers";
import TextInput from "../../components/TextInput";
import H4 from "../../components/Typography/H3";
import useDebounce from "../../hooks/useDebounce";
import Jikan, { AnimeTopRespType } from "../../services/jikan";

const Search = (props: any) => {
  const theme = useTheme();

  // component states
  const [animeName, setAnimeName] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [animeSearched, setAnimeSearched] = React.useState<AnimeTopRespType[]>(
    []
  );
  const debouncedAnimeSearch = useDebounce(animeName, 500);

  const searchAnimeByName = (name: string) => {
    if (name.length === 0) {
      return;
    }
    setLoading(true);
    Jikan.anime
      .search(name)
      .then((results) => setAnimeSearched(results))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    searchAnimeByName(debouncedAnimeSearch);
  }, [debouncedAnimeSearch]);

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <H4
        text="Start Searching"
        style={{ marginHorizontal: 22, marginTop: 4 }}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent:
            theme.dimensions.screenType === "large"
              ? "flex-start"
              : "space-between",
          alignItems: "center",
          marginHorizontal: 22,
        }}
      >
        <TextInput
          value={animeName}
          onChangeText={(t) => setAnimeName(t)}
          placeholder="Enter anime name"
          style={{
            marginHorizontal: 0,
            width:
              theme.dimensions.screenType === "large"
                ? 320
                : theme.dimensions.width - 22 * 2 - 40,
          }}
          autoFocus
          onEndEditing={() => searchAnimeByName(animeName)}
          onSubmitEditing={() => searchAnimeByName(animeName)}
          clearButtonMode="always"
        />
        <TouchableOpacity
          style={{
            width: 40,
          }}
          onPress={() => searchAnimeByName(animeName)}
        >
          <SearchIcon.default width={40} />
        </TouchableOpacity>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingVertical: 20 }}
      >
        <AnimeLister
          loading={loading}
          anime={animeSearched}
          navigate={props.navigation.navigate}
        />
      </ScrollView>
    </View>
  );
};

export default Search;
