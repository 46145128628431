import { useEffect, useState } from "react";

import {
  Anime,
  getAnimeData,
  toggleLikeAnime,
  setAnimeData,
} from "../services/firebase/anime";
import {
  bookmarkAnimeForUser,
  getUserData,
  User,
} from "../services/firebase/user";

export interface AnimeFuncCallerParams {
  type: "update" | "like" | "bookmark" | "share";
}

export type useAnimeFuncsReturn = [
  Anime | null,
  User | null,
  (p: AnimeFuncCallerParams) => void,
  Error | null
];

export default (key: string, userId: string): useAnimeFuncsReturn => {
  const [ownAnimeDetails, setOwnAnimeDetails] = useState<Anime | null>(null);
  const [userData, setUserData] = useState<User | null>(null);
  const [err, setErr] = useState<Error | null>(null);

  const getOwnAnimeDetails = () => {
    Promise.all([getUserData(String(userId)), getAnimeData(String(key))])
      .then(([userData, animeData]) => {
        setOwnAnimeDetails(animeData);
        setUserData(userData);
      })
      .catch((err) => {
        // console.log("hooks->useAnimeFuncs Error:", err);
        setErr(err);
      });
  };

  const toggleLike = async () => {
    // null and undefined check
    if (!ownAnimeDetails) {
      return;
    }
    const isLiked = ownAnimeDetails.liked.includes(userId);
    try {
      setOwnAnimeDetails({
        ...ownAnimeDetails,
        liked: isLiked
          ? ownAnimeDetails.liked.filter((uid) => uid !== userId)
          : [...ownAnimeDetails.liked, userId],
      });
      await toggleLikeAnime(String(key), userId, isLiked);
    } catch (err) {
      console.log(err);
    } finally {
      getOwnAnimeDetails();
    }
  };

  const toggleBookmark = async () => {
    // null and undefined check
    if (!ownAnimeDetails || !userData) {
      return;
    }
    try {
      const isBookmarked = userData.bookmarks.includes(String(key));
      setUserData({
        ...userData,
        bookmarks: isBookmarked
          ? userData.bookmarks.filter((id) => id !== String(key))
          : [...userData.bookmarks, String(key)],
      });
      await bookmarkAnimeForUser(
        userId,
        String(key),
        userData.bookmarks.includes(String(key))
      );
    } catch (err) {
      console.log(err);
    } finally {
      getOwnAnimeDetails();
    }
  };

  const increaseShareCount = async () => {
    try {
      if (ownAnimeDetails === null) {
        return;
      }
      await setAnimeData({
        ...ownAnimeDetails,
        shareCount: ownAnimeDetails?.shareCount
          ? ownAnimeDetails.shareCount + 1
          : 1,
      });
    } catch (err) {
      console.log(err);
    } finally {
      getOwnAnimeDetails();
    }
  };

  const animeFuncCaller = (params: AnimeFuncCallerParams) => {
    switch (params.type) {
      case "like":
        return toggleLike();
      case "update":
        return getOwnAnimeDetails();
      case "bookmark":
        return toggleBookmark();
      case "share":
        return increaseShareCount();
      default:
        getOwnAnimeDetails();
    }
  };

  useEffect(() => {
    getOwnAnimeDetails();
  }, []);

  return [ownAnimeDetails, userData, animeFuncCaller, err];
};
