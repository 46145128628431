import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";

import { getUserData, User, updateUser } from "../services/firebase/user";

export interface UserFuncCallerParams {
  type: "update" | "get";
  payload: object;
}

export default (): [User | null, (p: UserFuncCallerParams) => void] => {
  const auth = getAuth();
  const ownId = String(auth.currentUser?.uid);
  // hook states
  const [userData, setUserData] = useState<User | null>(null);

  const getUserDetails = () => {
    getUserData(ownId)
      .then((userData) => setUserData(userData))
      .catch((err) => console.log(err));
  };

  const userFuncCaller = (params: UserFuncCallerParams) => {
    switch (params.type) {
      case "update":
        return updateUser(ownId, params.payload);
      case "get":
        return getUserDetails();
      default:
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return [userData, userFuncCaller];
};
