import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  AuthErrorCodes,
} from "firebase/auth";

const signInWithEmail = (email: string, password: string) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password)
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

const signUpWithEmail = (email: string, password: string) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, email, password)
      .then((data) => sendEmailVerification(data.user))
      .then(() => signOut(auth))
      .then(() => resolve(null))
      .catch((error) => reject(error));
  });
};

export { signInWithEmail, signUpWithEmail, AuthErrorCodes };
