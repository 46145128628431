import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

import { useTheme } from "../Providers";

const Heart = (props: SvgProps & { isSelected?: boolean }) => {
  const theme = useTheme();
  return (
    <Svg
      width={24}
      height={24}
      fill={props.isSelected ? theme.colors.text : "none"}
      {...props}
    >
      <Path
        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.501 5.501 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.501 5.501 0 0 0 0-7.78v0Z"
        stroke={theme.colors.text}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Heart;
