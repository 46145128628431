import * as React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  View,
} from "react-native";

import { withTheme } from "../Providers";

export type FlatListItemProps = {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  theme: any;
};

const FlatListItem: React.FC<FlatListItemProps> = (props) => {
  const styles = StyleSheet.create({
    mainListItemWrapper: {
      backgroundColor: props.theme.colors.surface,
      padding: 7,
      paddingRight: 22,
      marginBottom: 18,
      borderRadius: props.theme.roundness,
      height: 80,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });

  if (props.onPress) {
    return (
      <TouchableOpacity
        style={[styles.mainListItemWrapper, props.style]}
        onPress={props.onPress}
      >
        {props.children}
      </TouchableOpacity>
    );
  }

  return (
    <View style={[styles.mainListItemWrapper, props.style]}>
      {props.children}
    </View>
  );
};

export default withTheme(FlatListItem);
