import { getAuth } from "firebase/auth";
import * as React from "react";
import { View, ScrollView, StyleSheet, Image } from "react-native";
// import { TouchableOpacity } from "react-native-gesture-handler";

import Alert, { AlertProps } from "../../components/Alert";
// import FlatStatsGrid from "../../components/FlatStatsGrid";
// import Bookmark from "../../components/Icons/Bookmark";
// import FollowPerson from "../../components/Icons/FollowPerson";
// import People from "../../components/Icons/People";
import AnimeLister from "../../components/AnimeLister";
import Loader from "../../components/Loader";
import { withTheme } from "../../components/Providers";
import H1 from "../../components/Typography/H1";
import H3 from "../../components/Typography/H3";
import { getAnimeData } from "../../services/firebase/anime";
import { getUserData, User } from "../../services/firebase/user";
import { AnimeTopRespType } from "../../services/jikan";

export interface ProfileProps {
  theme: any;
}

const Profile: React.FC<ProfileProps & any> = ({ theme, ...props }) => {
  const auth = getAuth();
  const userId = String(auth.currentUser?.uid);

  // component states
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [likedAnimeList, setLikedAnimeList] = React.useState<
    AnimeTopRespType[]
  >([]);
  // alert modal states
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState<AlertProps>({
    title: "",
    message: "",
  });

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      getUserData(userId)
        .then((user) => {
          setUser(user);
          const promiseAllArray = user.liked.map((animeId) => {
            return getAnimeData(animeId);
          });
          return Promise.all(promiseAllArray);
        })
        .then((likedAnimeList) => {
          setLikedAnimeList(
            likedAnimeList.map((anime) => ({
              mal_id: Number(anime.uid),
              title: anime.name,
              synopsis: anime.description,
              image_url: anime.image,
            }))
          );
          setLoading(false);
        })
        .catch((err) => {
          setAlertState({
            title: "Error",
            message: err.message,
            onConfirmText: "Okay",
          });
          setIsAlertOpen(true);
        });
    });
    return unsubscribe;
  }, []);

  const styles = StyleSheet.create({
    profilePictureWrapper: {
      marginTop: 50,
      backgroundColor: "#C4C4C4",
      width: 200,
      height: 200,
      borderRadius: theme.roundness,
      alignSelf: "center",
    },
  });

  if (user === null) {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Loader />
      </View>
    );
  }

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingVertical: 20 }}
      >
        <Image
          style={styles.profilePictureWrapper}
          source={{ uri: user.photo }}
        />
        <H1 text={user.name} style={{ alignSelf: "center", marginTop: 8 }} />

        {/* <FlatStatsGrid
          primary={{
            text: `${user.followers.length} followers`,
            icon: <FollowPerson />,
            onPress: () => SuccessAlert("Coming Soon", [{ text: "Close" }]),
          }}
          secondary={{
            text: `${user.following.length} following`,
            icon: <People />,
            onPress: () => {},
          }}
          tertiary={{
            text: `${user.bookmarks.length} bookmarks`,
            icon: <Bookmark />,
            onPress: () => {},
          }}
        /> */}
        <H3
          text="Liked Recently"
          style={{ marginTop: 22, marginBottom: 8, marginHorizontal: 23 }}
        />
        <AnimeLister
          loading={loading}
          anime={likedAnimeList}
          navigate={props.navigation.navigate}
        />
      </ScrollView>
      <Alert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        {...alertState}
      />
    </View>
  );
};

export default withTheme(Profile);
