import AsyncStorage from "@react-native-async-storage/async-storage";

export const storeData = (key: string, value: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      await AsyncStorage.setItem(key, value);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
};

export const readData = (key: string): Promise<string | false> => {
  return new Promise(async (resolve, reject) => {
    try {
      const value = await AsyncStorage.getItem(key);
      if (value !== null) {
        return resolve(value);
      } else {
        resolve(false);
      }
    } catch (err) {
      reject(err);
    }
  });
};
