import { getAuth } from "firebase/auth";
import * as React from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";

import useAnimeFuncs from "../../hooks/useAnimeFuncs";
import { setAnimeData } from "../../services/firebase/anime";
import Bookmark from "../Icons/Bookmark";
import Heart from "../Icons/Heart";
import Share from "../Icons/Share";
import { useTheme } from "../Providers";
import Sharable from "../Sharable/Sharable";
import H3 from "../Typography/H3";
import H4 from "../Typography/H4";

export type AnimeCardProps = {
  id: string;
  name: string;
  description: string;
  image: string;
  style?: any;
  onPress: () => void;
};

const AnimeCard: React.FC<AnimeCardProps> = (props) => {
  const theme = useTheme();
  const auth = getAuth();
  const userId = String(auth.currentUser?.uid);
  const [ownAnimeData, userData, animeFuncCaller, useAnimeErr] = useAnimeFuncs(
    String(props.id),
    userId
  );
  const [isShareOpen, setIsShareOpen] = React.useState(false);

  React.useEffect(() => {
    // each time anime card is rendered
    // make sure we have required anime details in our own db
    // to avoid rate limit by Jikan
    if (useAnimeErr !== null) {
      if (useAnimeErr.message === "animeNotRecordedYet") {
        setAnimeData({
          uid: props.id,
          name: props.name,
          description: props.description,
          image: props.image,
          liked: [],
          shareCount: 0,
        })
          .then(() => animeFuncCaller({ type: "update" }))
          .catch((err) => alert(err.message));
      }
    }
  }, [useAnimeErr]);

  const styles = StyleSheet.create({
    mainCardWrapper: {
      backgroundColor: theme.colors.surface,
      padding: 7,
      marginBottom: 18,
      borderRadius: theme.roundness,
      borderColor: theme.colors.text,
      borderWidth: 1,
      elevation: theme.elevation,
    },
    animeImage: {
      height: 140,
      backgroundColor: "grey",
      borderRadius: theme.roundness,
      marginBottom: 4,
    },
    textMarginTop: {
      marginTop: 2 * theme.dimensions.scale,
    },
    buttonWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: 8,
    },
    button: {
      marginLeft: 12,
    },
  });
  return (
    <TouchableOpacity
      style={[styles.mainCardWrapper, props.style]}
      onPress={props.onPress}
    >
      <Image source={{ uri: props.image }} style={styles.animeImage} />
      <H3 style={styles.textMarginTop} text={props.name} />
      <H4
        style={styles.textMarginTop}
        text={
          props.description.length > 128
            ? `${props.description.slice(0, 128)}...more`
            : props.description
        }
      />
      <View style={styles.buttonWrapper}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => animeFuncCaller({ type: "like" })}
        >
          <Heart isSelected={ownAnimeData?.liked.includes(userId)} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            setIsShareOpen(true);
            animeFuncCaller({ type: "share" });
          }}
        >
          <Share />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={() => animeFuncCaller({ type: "bookmark" })}
        >
          <Bookmark
            isSelected={userData?.bookmarks.includes(String(props.id))}
          />
        </TouchableOpacity>
      </View>
      <Sharable
        name={props.name}
        description={props.description}
        image={props.image}
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
      />
    </TouchableOpacity>
  );
};

export default AnimeCard;
