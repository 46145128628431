import "react-native-gesture-handler";
import "./src/services/firebase/index";
import "react-native-web";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "expo-status-bar";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import * as React from "react";
import { Platform, View, LogBox } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import Loader from "./src/components/Loader";
import Providers, { withTheme } from "./src/components/Providers";
import RootNavigator from "./src/navigators";
import ErrorBoundary from "./src/screens/ErrorBoundary";

/**
 * SetTimeout Error on React Native
 * This happens because firebase uses setTimeout
 * with long intervals, which is not great for performance.
 * Right now there is no fix for this warning other then ignoring it
 */
LogBox.ignoreLogs(["Setting a timer"]);

const auth = getAuth();

const InternalAligner = withTheme((props: any) => {
  return (
    <View
      style={{
        flex: 1,
        height: props.theme.dimensions.height,
        width: props.theme.dimensions.width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: props.theme.colors.background,
      }}
    >
      <SafeAreaView
        style={{
          height: "100%",
          width: props.theme.dimensions.width,
          backgroundColor: props.theme.colors.background,
        }}
      >
        <StatusBar style="auto" hidden />
        {props.fontIsReady ? (
          <RootNavigator isLoggedIn={props.isLoggedIn} />
        ) : null}
      </SafeAreaView>
    </View>
  );
});

export default function App() {
  const [authIsReady, setAuthIsReady] = React.useState(false);
  const [fontIsReady] = useFonts({
    JosefinSans: require("./assets/fonts/JosefinSans.ttf"),
  });
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      await SplashScreen.preventAutoHideAsync();
    })();
    onAuthStateChanged(auth, (user) => {
      // if user logs in without verifying there email
      if (user !== null && !user?.emailVerified) {
        signOut(auth);
      }
      setIsLoggedIn(user !== null ? user.emailVerified : false);
      setTimeout(() => {
        setAuthIsReady(true);
      }, 1000);
    });

    // if the platform is web
    if (Platform.OS === "web") {
      const root = document.getElementById("root");
      if (root) {
        root.style.overflow = "hidden";
      }
    }
  }, []);

  if (fontIsReady && authIsReady) {
    SplashScreen.hideAsync();
  } else {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Loader />
      </View>
    ); // don't show anything and wait for app to be ready
  }

  return (
    <ErrorBoundary>
      <Providers>
        <InternalAligner fontIsReady={fontIsReady} isLoggedIn={isLoggedIn} />
      </Providers>
    </ErrorBoundary>
  );
}
