export type ScreenType = "small" | "medium" | "large";
const screenType = "small";

const theme = {
  name: 'light',
  roundness: 8,
  elevation: 8,
  colors: {
    background: "#F9F9F9",
    text: "#373D3F",
    link: "#007FFF",
    surface: "#FFFFFF",
  },
  fonts: {
    smallest: 12,
    small: 14,
    medium: 18,
    large: 24,
  },
  dimensions: {
    width: 0,
    height: 0,
    scale: 1,
    screenType,
  },
  funcs: {
    updateTheme: async (themeName: string) => {},
    getTheme: async () => {},
  },
};

const DefaultTheme = theme;

const DarkTheme = {
  ...theme,
  name: 'dark',
  colors: {
    background: "#0F0E0E",
    text: "#EEEEEE",
    link: "#B2FFFF",
    surface: "#191919",
  },
};

export default {
  light: DefaultTheme,
  dark: DarkTheme,
};
