import * as React from "react";
import { View } from "react-native";

import { AnimeDetailRespType, AnimeTopRespType } from "../../services/jikan";
import AnimeCard from "../AnimeCard";
import Loader from "../Loader";
import { useTheme } from "../Providers";

export interface AnimeListerProps {
  loading: boolean;
  anime: AnimeTopRespType[] | AnimeDetailRespType[];
  navigate: (r: string, a: any) => void;
}

const AnimeLister: React.FC<AnimeListerProps> = (props) => {
  const theme = useTheme();
  return (
    <View
      style={{
        display: "flex",
        flexDirection:
          theme.dimensions.screenType === "large" ? "row" : "column",
        alignItems:
          theme.dimensions.screenType === "large" ? "flex-start" : "center",
        justifyContent:
          theme.dimensions.screenType === "large" ? "flex-start" : "center",
        flexWrap: theme.dimensions.screenType === "large" ? "wrap" : "nowrap",
        width: "100%",
        paddingHorizontal: 12,
      }}
    >
      {!props.loading ? (
        props.anime.map((anime: AnimeTopRespType | AnimeDetailRespType) => {
          return (
            <AnimeCard
              key={String(anime.mal_id)}
              id={String(anime.mal_id)}
              name={anime.title}
              description={anime.synopsis}
              image={anime.image_url}
              style={{
                width: theme.dimensions.screenType === "large" ? 360 : "100%",
                marginHorizontal:
                  theme.dimensions.screenType === "large" ? 10 : 0,
              }}
              onPress={() =>
                props.navigate("anime", {
                  key: anime.mal_id,
                  name: anime.title,
                  image: anime.image_url,
                })
              }
            />
          );
        })
      ) : (
        <Loader />
      )}
    </View>
  );
};

export default AnimeLister;
