import React from "react";
import { View, Modal as DefaultModal } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

// importing components
import { useTheme } from "../Providers";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = (props) => {
  const theme = useTheme();

  return (
    <DefaultModal
      animationType="slide"
      transparent
      visible={props.isOpen}
      onRequestClose={() => props.onClose()}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          margin: 0,
          padding: 0,
        }}
      >
        <View
          style={{
            backgroundColor: theme.colors.surface,
            maxHeight: theme.dimensions.height * 0.8,
            width:
              theme.dimensions.screenType === "large"
                ? 400
                : theme.dimensions.width * 0.9,
            borderColor: theme.colors.text,
            borderWidth: 1,
            borderRadius: theme.roundness,
            padding: 16,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
          }}
        >
          <ScrollView showsVerticalScrollIndicator={false}>
            {props.children}
          </ScrollView>
        </View>
      </View>
    </DefaultModal>
  );
};

export default Modal;
