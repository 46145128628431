import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCxR5H3cbt2rd6SDkszThL6GrfGfe_XX-4",
  authDomain: "animeveda-c8d3f.firebaseapp.com",
  databaseURL: "https://animeveda-c8d3f-default-rtdb.firebaseio.com",
  projectId: "animeveda-c8d3f",
  storageBucket: "animeveda-c8d3f.appspot.com",
  messagingSenderId: "515871082506",
  appId: "1:515871082506:web:9cbaf18e3a5ddf43eab7f3",
};

initializeApp(firebaseConfig);

const auth = getAuth();
setPersistence(auth, browserLocalPersistence);
