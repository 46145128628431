import { DrawerContentComponentProps } from "@react-navigation/drawer";
import * as React from "react";
import {
  StyleSheet,
  ScrollView,
  View,
  TouchableOpacity,
  Image,
} from "react-native";
import { SvgProps } from "react-native-svg";

import useUserData from "../../hooks/useUserData";
import Bookmark from "../Icons/Bookmark";
import Home from "../Icons/Home";
import Setting from "../Icons/Setting";
import User from "../Icons/User";
import { withTheme } from "../Providers";
import H2 from "../Typography/H2";

type DrawerItemProps = {
  icon?: (props: SvgProps) => JSX.Element;
  title: string;
  onPress?: () => void;
  styles?: any;
};

const DrawerItem = (props: DrawerItemProps) => {
  const styles = StyleSheet.create({
    itemWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 18,
    },
    title: {
      marginLeft: props.icon ? 12 : 0,
    },
  });
  return (
    <TouchableOpacity style={[styles.itemWrapper, props.styles]} onPress={props.onPress}>
      {props.icon && props.icon({ width: 24, height: 24 })}
      <H2 text={props.title} style={styles.title} />
    </TouchableOpacity>
  );
};

export type DrawerProps = DrawerContentComponentProps & {
  theme: any;
};

const Drawer = (props: DrawerProps) => {
  const [profileWrapperHeight, setProfileWrapperHeight] = React.useState(0);
  const [userData, userFunc] = useUserData();
  const styles = StyleSheet.create({
    mainDrawerWrapper: {
      flex: 1,
      flexDirection: "column",
      backgroundColor: props.theme.colors.background,
      padding: 22,
    },
    profileWrapper: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: profileWrapperHeight,
      backgroundColor: props.theme.colors.surface,
      borderRadius: props.theme.roundness,
      marginBottom: 22,
    },
    profileImage: {
      backgroundColor: "#C4C4C4",
      width: "40%",
      height: "40%",
      borderRadius: props.theme.roundness,
    },
    profileName: {
      marginTop: 18,
    },
  });

  React.useEffect(() => {
    userFunc({
      type: "get",
      payload: {},
    });
  }, [props.state]);

  return (
    <ScrollView contentContainerStyle={styles.mainDrawerWrapper}>
      <View
        style={styles.profileWrapper}
        onLayout={(e) => setProfileWrapperHeight(e.nativeEvent.layout.width)}
      >
        <Image source={{ uri: userData?.photo }} style={styles.profileImage} />
        <H2 text={String(userData?.name)} style={styles.profileName} />
      </View>
      <DrawerItem
        icon={Home}
        title="Home"
        onPress={() => props.navigation.navigate("home")}
      />
      <DrawerItem
        icon={User}
        title="My Profile"
        onPress={() => props.navigation.navigate("profile")}
      />
      <DrawerItem
        icon={Bookmark}
        title="Bookmarks"
        onPress={() => props.navigation.navigate("bookmarks")}
      />
      {/* <DrawerItem
        icon={Clock}
        title="History"
        onPress={() => props.navigation.navigate("home")}
      /> */}
      <DrawerItem
        icon={Setting}
        title="Settings"
        onPress={() => props.navigation.navigate("settings")}
      />
      <DrawerItem
        styles={{
          position: 'absolute',
          bottom: 0,
          right: 10,
        }}
        title="Build with ❤️ in India"
      />
    </ScrollView>
  );
};

export default withTheme(Drawer);
