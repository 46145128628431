import React from "react";
import { Text, View, TouchableOpacity } from "react-native";

import { setErrorData, ErrorReport } from "../../services/firebase/error";

interface ErrorBoundaryProps {
  children: any;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    // You can also log the error to an error reporting service
    const errorReport: ErrorReport = {
      error: error.message,
      stack: error.stack?.toString(),
      name: error.name,
      info: JSON.stringify(info),
    };
    setErrorData(errorReport);
  }

  render() {
    if (this.state.hasError) {
      return (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{ fontSize: 28, textAlign: "center", fontWeight: "bold" }}
          >
            Ops!!!
          </Text>
          <Text
            style={{
              fontSize: 18,
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 4,
            }}
          >
            We ran into an error and have recovered, this has been reported
            automatically and will be fixed in next releases.
          </Text>
          <TouchableOpacity
            onPress={() => {
              this.setState({
                hasError: false,
              });
            }}
          >
            <Text
              style={{
                fontSize: 18,
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 8,
              }}
            >
              Continue
            </Text>
          </TouchableOpacity>
        </View>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
