import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import Alert, { AlertProps } from "../../components/Alert";
import Button from "../../components/Button";
import { withTheme } from "../../components/Providers";
import TextInput from "../../components/TextInput";
import H1 from "../../components/Typography/H1";
import H4 from "../../components/Typography/H4";
// import firebase
import {
  AuthErrorCodes,
  signInWithEmail,
  signUpWithEmail,
} from "../../services/firebase/authentication";

export type LoginProps = {
  theme: any;
};

const Login = (props: LoginProps) => {
  const [type, setType] = useState<"login" | "register">("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // alert component states
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState<AlertProps>({
    title: "",
    message: "",
  });
  const [processing, setProcessing] = useState(false);

  const onPressLogin = () => {
    if (processing) {
      return;
    }
    setProcessing(true);
    signInWithEmail(email, password)
      .then((data: any) => {
        if (!data.user.emailVerified) {
          setAlertState({
            title: "Hmm...",
            message: "Please verify your email address and try again!",
            onConfirm: () => {
              onPressLogin();
              setIsAlertOpen(false);
            },
            onConfirmText: "Try Again",
          });
          setIsAlertOpen(true);
        }
      })
      .catch((err) => handleError(err))
      .finally(() => setProcessing(false));
  };

  const onPressSignup = () => {
    if (processing) {
      return;
    }
    setProcessing(true);
    signUpWithEmail(email, password)
      .then(() => {
        setAlertState({
          title: "Success",
          message:
            "You are now registered! Confirm your email and try signing in!",
          onConfirm: () => {
            setType("login");
            onPressLogin();
            setIsAlertOpen(false);
          },
          onConfirmText: "Login",
        });
        setIsAlertOpen(true);
      })
      .catch((err) => handleError(err))
      .finally(() => setProcessing(false));
  };

  const handleError = (err: any) => {
    switch (err.code) {
      case "auth/wrong-password":
        setAlertState({
          title: "Hmm...",
          message: "Ops you typed the wrong password!",
          onCancel: () => {
            console.log("Forget Password!");
            setIsAlertOpen(false);
          },
          onCancelText: "Forgot",
          onConfirm: () => setIsAlertOpen(false),
          onConfirmText: "Try Again",
        });
        break;
      case "auth/email-already-in-use":
        setAlertState({
          title: "Hmm...",
          message: "Looks like you are already registered! Login instead",
          onConfirm: () => {
            setType("login");
            onPressLogin();
            setIsAlertOpen(false);
          },
          onConfirmText: "Login",
        });
        break;
      case "auth/user-not-found":
        setAlertState({
          title: "Hmm...",
          message:
            "Hey you haven't joined the family yet, would you like to sign up with us?",
          onConfirm: () => {
            setIsAlertOpen(false);
            onPressSignup();
          },
          onConfirmText: "Sign Up",
        });
        break;
      case AuthErrorCodes.INVALID_EMAIL:
        setAlertState({
          title: "Hmm...",
          message:
            "Looks like you tried login without properly entering your email. Please verify your email!",
          onConfirm: () => {
            setIsAlertOpen(false);
          },
          onConfirmText: "Okay",
        });
        break;
      default:
        setAlertState({
          title: "Hmm...",
          message: err.message,
          onConfirmText: "Okay",
        });
    }
    setIsAlertOpen(true);
  };

  const styles = StyleSheet.create({
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    switchButton: {
      position: "absolute",
      right: 10,
      bottom: 10,
    },
    bottomTextButton: {
      textDecorationLine: "underline",
    },
  });

  return (
    <View style={styles.mainWrapper}>
      <H1 text={`${type === "login" ? "Login" : "Register"} to AnimeVeda`} />
      <TextInput
        value={email}
        onChangeText={(t: string) => setEmail(t)}
        placeholder="Email"
        style={{
          marginTop: 20,
        }}
      />
      <TextInput
        value={password}
        onChangeText={(t: string) => setPassword(t)}
        placeholder="Password"
        secureTextEntry
      />
      <Button
        text={type === "login" ? "Login" : "Sign Up"}
        loading={processing}
        onPress={type === "login" ? onPressLogin : onPressSignup}
        style={{ marginTop: 10 }}
      />
      <TouchableOpacity
        style={styles.switchButton}
        onPress={() => setType(type === "login" ? "register" : "login")}
      >
        <H4
          text={
            type === "login"
              ? "Not registered? Register now!"
              : "Already registered? Login Now!"
          }
          style={styles.bottomTextButton}
        />
      </TouchableOpacity>
      <Alert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        {...alertState}
      />
    </View>
  );
};

export default withTheme(Login);
