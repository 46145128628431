import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { SvgProps } from "react-native-svg";

import { withTheme } from "../Providers";
import H2 from "../Typography/H2";
import FlatListItemBase from "./FlatListItemBase";

export type FlatListItemProps = {
  title: string;
  leftIcon?: (props: SvgProps) => JSX.Element;
  rightIcon?: (props: SvgProps) => JSX.Element;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  theme: any;
};

const FlatListItem = (props: FlatListItemProps) => {
  const styles = StyleSheet.create({
    mainListItemWrapper: {
      backgroundColor: props.theme.colors.surface,
      padding: 7,
      paddingRight: 22,
      marginBottom: 18,
      borderRadius: props.theme.roundness,
      height: 80,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    innerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: 15, // 22 - 7 = 15
    },
  });
  return (
    <FlatListItemBase
      style={[styles.mainListItemWrapper, props.style]}
      onPress={props.onPress}
    >
      <View style={styles.innerContainer}>
        {props.leftIcon && <props.leftIcon width={24} height={24} />}
        <H2 style={{ marginLeft: 16 }} text={props.title} />
      </View>
      {props.rightIcon && <props.rightIcon width={24} height={24} />}
    </FlatListItemBase>
  );
};

export default withTheme(FlatListItem);
