import React from "react";
import { View } from "react-native";

import Button from "../Button";
import Modal, { ModalProps } from "../Modal";
import H2 from "../Typography/H2";
import H3 from "../Typography/H3";

export interface AlertProps {
  title: string;
  message: string;
  onCancel?: () => void;
  onCancelText?: string;
  onConfirm?: () => void;
  onConfirmText?: string;
}

const Alert: React.FC<ModalProps & AlertProps> = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <View
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <H2 style={{ alignSelf: "flex-start" }} text={props.title} />
        <H3
          style={{ marginTop: 16, alignSelf: "flex-start" }}
          text={props.message}
        />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 10,
            width: "100%",
          }}
        >
          <Button
            text={props.onCancelText || "Cancel"}
            onPress={props.onCancel || props.onClose}
          />
          <Button
            text={props.onConfirmText || "Confirm"}
            onPress={props.onConfirm || props.onClose}
          />
        </View>
      </View>
    </Modal>
  );
};

export default Alert;
